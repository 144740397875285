<template>
  <div class="mt-20 management">
    <!-- <Editor :content="content" /> -->
    <div
      class="relative flex flex-col lg:flex-row h-auto flex-1 w-full pt-4 pb-20"
    >
      <div class="w-full lg:w-[300px] flex flex-col">
        <div
          :class="selected === '' ? 'font-bold bg-white' : ''"
          class="p-4 hover:bg-gray-100 cursor-pointer border-b"
          @click="selected = ''"
        >
          Bolagsstyrning
        </div>
        <div
          :class="selected === 'bolagsordning' ? 'font-bold bg-white' : ''"
          class="p-4 hover:bg-gray-100 cursor-pointer border-b"
          @click="selected = 'bolagsordning'"
        >
          Bolagsordning
        </div>
        <div
          :class="selected === 'bolagstammor' ? 'font-bold bg-white' : ''"
          class="p-4 hover:bg-gray-100 cursor-pointer border-b"
          @click="selected = 'bolagstammor'"
        >
          Bolagsstämmor
        </div>
        <div
          :class="selected === 'ledning' ? 'font-bold bg-white' : ''"
          class="p-4 hover:bg-gray-100 cursor-pointer border-b"
          @click="selected = 'ledning'"
        >
          Ledning & styrelse
        </div>
        <div
          :class="selected === 'styrelsens-arbete' ? 'font-bold bg-white' : ''"
          class="p-4 hover:bg-gray-100 cursor-pointer border-b"
          @click="selected = 'styrelsens-arbete'"
        >
          Styrelsens arbete
        </div>
      </div>
      <div class="w-full h-full flex flex-1 flex-grow p-4">
        <div v-if="selected === 'bolagsordning'">
          <div class="w-full max-w-[1000px]">
            <h1>
              Bolagsordning
            </h1>
            <p>§ 1 Företagsnamn</p>
            <p>Bolagets företagsnamn är Windon Energy Group AB. Bolaget är publikt (publ)</p>
            <p>§ 2 Styrelsens säte</p>
            <p>Styrelsen skall ha sitt säte i Stockholm.</p>
            <p>§ 3 Verksamhet</p>
            <p>Bolaget ska äga och förvalta fast och lös egendom, tillhandahålla administrativa tjänster samt därmed förenlig verksamhet.</p>
            <p>§ 4 Aktiekapital</p>
            <p>Aktiekapitalet skall utgöra lägst 1 200 000 kronor och högst 4 800 000 kronor.</p>
            <p>§ 5 Antalet aktier</p>
            <p>Antalet aktier skall vara lägst 12 000 000 och högst 48 000 000 aktier.</p>
            <p>§ 6 Styrelse</p>
            <p>Styrelsen skall bestå av lägst fyra och högst åtta ledamöter utan suppleanter. Ledamöterna väljes årligen på årsstämma för</p>
            <p>tiden intill slutet av nästa årsstämma.</p>
            <p>§ 7 Revisorer</p>
            <p>Bolaget skall ha en (1) eller två (2) revisorer med högst två (2) suppleanter, alternativt ett (1) registrerat revisionsbolag.</p>
            <p>§ 8 Kallelse till bolagsstämma</p>
            <p>Kallelse till bolagsstämma skall ske genom annonsering i Post- och Inrikes Tidningar samt på bolagets webbplats. Att kallelse</p>
            <p>skett skall annonseras i Svenska Dagbladet.</p>
            <p>§ 9 Anmälan om, samt rätt till deltagande i bolagsstämma</p>
            <p>För att få delta i bolagsstämma skall aktieägare anmäla detta till bolaget senast den dag som anges i kallelsen till stämman.</p>
            <p>Denna dag får ej vara söndag, annan allmän helgdag, lördag, midsommarafton, julafton eller nyårsafton och inte heller infalla</p>
            <p>tidigare än femte vardagen före stämman.</p>
            <p>Aktieägare får medföra ett eller två biträden till bolagsstämman endast om aktieägare på sätt som anges i föregående stycke</p>
            <p>anmäler antal biträden denne har för avsikt att medföra.</p>
            <p>§ 10 Fullmaktsinsamling och poströstning</p>
            <p>Styrelsen äger rätt att samla in fullmakter i enlighet med det förfarande som beskrivs i 7 kap. 4 § stycke 2 aktiebolagslagen</p>
            <p>(2005:551).</p>
            <p>Styrelsen äger rätt att inför bolagsstämma besluta om att aktieägare skall ha rätt att förhandsrösta per post före</p>
            <p>bolagsstämman.</p>
            <p>§ 11 Årsstämma</p>
            <p>Vid årsstämma som skall hållas årligen inom sex månader efter räkenskapsårets utgång, skall följande ärenden förekomma:</p>
            <p>Val av ordförande vid stämman;</p>
            <p>Upprättande och godkännande av röstlängd;</p>
            <p>Val av en eller två personer att jämte ordföranden justera stämmans protokoll;</p>
            <p>Prövning av om stämman blivit behörigen sammankallad;</p>
            <p>Godkännande av dagordning;</p>
            <p>Framläggande av årsredovisningen och revisionsberättelsen samt, i förekommande fall, koncernredovisningen och</p>
            <p>koncernrevisionsberättelsen;</p>
            <p>Beslut om</p>
            <p>a) fastställelse av resultaträkningen och balansräkningen samt, i förekommande fall, koncernresultaträkningen och</p>
            <p>koncernbalansräkningen;</p>
            <p>b) dispositioner beträffande bolagets vinst eller förlust enligt den fastställda balansräkningen;</p>
            <p>c) ansvarsfrihet för styrelseledamöterna och verkställande direktören;</p>
            <p>Fastställande av antalet styrelseledamöter och revisorer och i förekommande fall revisorssuppleant;</p>
            <p>Bestämmande av arvoden till styrelsen och revisor;</p>
            <p>Val av styrelseledamöter och revisor samt, i förekommande fall, styrelsesuppleant och revisorssuppleant;</p>
            <p>Annat ärende, som ankommer på stämman enligt aktiebolagslagen (2005:551) eller bolagsordningen.</p>
            <p>§ 12 Räkenskapsår</p>
            <p>Kalenderåret skall utgöra bolagets räkenskapsår.</p>
            <p>§ 13 Avstämningsförbehåll</p>
            <p>Bolagets aktier skall vara registrerade i ett avstämningsregister enligt lagen (1998:1479) om värdepapperscentraler och</p>
            <p>kontoföring av finansiella instrument. Den aktieägare eller förvaltare som på avstämningsdagen är införd i aktieboken och</p>
            <p>antecknad i ett avstämningsregister enligt 4 kap. nämnda lag eller den som är antecknad på avstämningskonto enligt 4 kap.</p>
            <p>18 § första stycket punkterna 6-8 nämnda lag skall antas vara behörig att utöva de rättigheter som följer av 4 kap. 39 §</p>
            <p>aktiebolagslagen (2005:551).</p>
            <p class="mt-4">
              Förslag till bemyndigande
            </p>
            <p>
              Styrelsen föreslår att bolagsstämman bemyndigar styrelsen att, vid ett eller flera tillfällen, under tiden fram till
              nästkommande årsstämma, med eller utan företrädesrätt för aktieägarna, besluta om nyemission av aktier,
              emission av konvertibler och teckningsoptioner. Emissionsbeslutet ska kunna ske mot kontant betalning
              och/eller med bestämmelse om apport eller kvittning eller att teckning ska kunna ske med andra villkor. Skälet
              till att styrelsen skall kunna fatta beslut om emission med avvikelse från aktieägares företrädesrätt och/eller med
              bestämmelse om apport- och kvittningsemission eller eljest med villkor enligt ovan är att bolaget skall kunna
              emittera aktier, teckningsoptioner och/eller konvertibler i samband med förvärv av bolag eller rörelser, samt
              kunna genomföra riktade emissioner i syfte att införskaffa kapital till bolaget. Emissionen får maximalt motsvara
              20% av utestående aktier i bolaget. Styrelsen eller den styrelsen utser bemyndigas att vidta de smärre justeringar
              som krävs för beslutets registrering vid Bolagsverket.
            </p>
          </div>
        </div>
        <div v-if="selected === 'bolagstammor'">
          <div class="w-full max-w-[1000px]">
            <h1>
              Bolagsstämmor
            </h1>

            <p>
              Aktieägarnas rätt att besluta i HODLs angelägenheter utövas vid bolagsstämman, som är bolagets högsta beslutande organ. Årsstämman ska hållas inom sex månader efter räkenskapsårets utgång och ska äga rum i Stockholm.
            </p>
            <h3 class="text-2xl my-6">
              <strong>Årsstämma 2023</strong>
            </h3>
            <p>
              <a
                class="text-custom-blue-500"
                href="https://assets.ctfassets.net/ebldurwszic0/1f20z4qP0Xlb7yUtcRK3J0/d96174d181f97b4b5af4fe78e0541661/Kallelse_a__rssta__mma_2023.pdf"
                title="Kallelse årsstämma 2023.pdf"
              >Kallelse Årsstämma 2023</a>
            </p>
            <p>
              <a
                class="text-custom-blue-500"
                href="https://assets.ctfassets.net/ebldurwszic0/67lcqVwl4Seo8VVu04PIjR/65f5f6d024896195e1d73186cdf85563/HODL_FULLMAKT_A__rssta__mma.pdf"
                title="HODL FULLMAKT Årsstämma.pdf"
              >HODL Fullmakt bolagsstämma 17 Maj 2023</a>
            </p>
            <p>
              <a
                class="text-custom-blue-500"
                href="https://assets.ctfassets.net/ebldurwszic0/0OzzmIWNmOlKs57hMRejt/2e2fa6e5747f3ccc466e311883bbf87c/Styrelsens_fo__rslag.pdf"
                title="Styrelsens förslag.pdf"
              >Styrelsens förslag</a>
            </p>
            <h3 class="text-2xl my-6">
              <strong>Extra Bolagsstämma från HODL SPAC Europe AB</strong>
            </h3>
            <p>
              <a
                class="text-custom-blue-500"
                href="https://assets.ctfassets.net/ebldurwszic0/7vcz3Y2JnKgabjamOhWri6/c763ccfd32c5ccc76f140f11fe874b3b/Kallelse_extra_bolagssta__mma_2023.pdf"
                title="Kallelse extra bolagsstämma 2023.pdf"
              >HODL Kallelse extra bolagsstämma 14 April (klicka på länk)</a>
            </p>
            <p>
              <a
                class="text-custom-blue-500"
                href="https://assets.ctfassets.net/ebldurwszic0/1Aoa1wSe9gD6U1eov6MEWd/e752afa24b9b89b32ab53510ad4da0b1/Fullmakt_extra_bolagssta__mma_HODL_14_april.pdf"
                title="Fullmakt extra bolagsstämma HODL 14 april.pdf"
              >HODL Fullmakt extra bolagsstämma 14 April (klicka på länk)</a>
            </p>
            <h3 class="text-2xl my-6">
              <strong>Senaste dokumentationen från HODL SPAC Europe AB</strong>
            </h3>
            <p>
              <a
                class="text-custom-blue-500"
                href="https://assets.ctfassets.net/ebldurwszic0/4zEr71XOXndcTVEEJZPEFR/86613e1b476c660549efb4a502327ede/A__R_Windon_AB_2022-12-31.pdf"
                title="ÅR Windon AB 2022-12-31.pdf"
              >ÅR Windon AB 2022-12-31</a>
            </p>
            <p>
              <a
                class="text-custom-blue-500"
                href="https://assets.ctfassets.net/ebldurwszic0/1teJoHwi26R0Ocm3AR6QMP/cae20ee71c0c868475029c24fc62561e/A__rsredovisning_HODL_SPAC_Europe_AB_2022_med_revisionsbera__ttelse.pdf"
                title="Årsredovisning HODL SPAC Europe AB 2022 med revisionsberättelse.pdf"
              >Årsredovisning HODL SPAC Europe AB 2022 med revisionsberättelse</a>
            </p>
            <p>
              <a
                class="text-custom-blue-500"
                href="https://downloads.ctfassets.net/ebldurwszic0/RZ6gYRft4SyED0560nFpM/1d59fdcecf5c66f5db5921259da779e8/Windon_-_Fo__retagspresentation_-_April_2023__new__-_2023-04-25_10.14.14.pdf"
                title="Windon - Företagspresentation - April 2023 (new) - 2023-04-25 10.14.14.pdf"
              >Windon - Presentation 2023 April</a>
            </p>
            <p>
              <a
                class="text-custom-blue-500"
                href="https://assets.ctfassets.net/ebldurwszic0/2S4IV4KtwtaFHgKyZ3liRq/4d46be15aea8d365ab3b32c0c7e2c18c/A__R_2021_och_revisionsbera__ttelse_final.pdf"
                title="ÅR 2021 och revisionsberättelse final.pdf"
              >ÅR 2021 &amp; Revisionsberättelse</a>
              (klicka på länk)
            </p>
            <p>
              <a
                class="text-custom-blue-500"
                href="https://assets.ctfassets.net/ebldurwszic0/70ijz3Lkz3jl3RCrnI4lIc/54b0b7c4aef550e10769bc7a93d9f68f/HODL_Fullmakt.pdf"
                title="HODL Fullmakt.pdf"
              >HODL Fullmakt</a>
              (klicka på länk)
            </p>
            <p>
              <a
                class="text-custom-blue-500"
                href="https://assets.ctfassets.net/ebldurwszic0/GYlnf5gbGON3OQKgagtg6/f1064952889d6b00ed621042a1f38d0b/HODL_Kallelse.pdf"
                title="HODL Kallelse.pdf"
              >HODL Kallelse</a>
              (klicka på länk)
            </p>
            <p class="my-2" />
          </div>
        </div>
        <div v-if="selected === 'ledning'">
          <div class="w-full max-w-[1000px]">
            <h1>
              Ledning &amp; styrelse
            </h1>

            <div class="grid grid-cols-2 lg:grid-cols-3 gap-4">
              <div class="bg-white shadow-md rounded">
                <img
                  class="rounded-t w-full h-64 object-cover object-top bg-white"
                  src="/henrik.png"
                >
                <div class="py-4 px-8">
                  <h3 class="text-lg">
                    Henrik Karstensen
                  </h3>
                  <p class="text-sm">
                    VD
                  </p>
                </div>
              </div>
              <div class="bg-white shadow-md rounded">
                <img
                  class="rounded-t w-full h-64 object-cover object-top bg-white"
                  src="//images.ctfassets.net/ebldurwszic0/3wL44zbshLHuJW7OXl2h4q/94028218f35c8f399f554235f606459e/1637315489931.jpg"
                >
                <div class="py-4 px-8">
                  <h3 class="text-lg">
                    Sebastian Hjorth
                  </h3>
                  <p class="text-sm">
                    CFO
                  </p>
                </div>
              </div>
              <div class="bg-white shadow-md rounded">
                <img
                  class="rounded-t w-full h-64 object-cover object-top bg-white"
                  src="/christer.png"
                >
                <div class="py-4 px-8">
                  <h3 class="text-lg">
                    Christer Nilsson
                  </h3>
                  <p class="text-sm">
                    CTO
                  </p>
                </div>
              </div>
              <div class="bg-white shadow-md rounded">
                <img
                  class="rounded-t w-full h-64 object-cover object-top bg-white"
                  src="//images.ctfassets.net/ebldurwszic0/6E2PVgtRQud2AItSJiV8My/f662c683ddfd49d4a2ec31d7791e6880/Johan_Kjell.jpg"
                >
                <div class="py-4 px-8">
                  <h3 class="text-lg">
                    Johan  Kjell
                  </h3>
                  <p class="text-sm">
                    Styrelseordförande
                  </p>
                </div>
              </div>
              
              <div class="bg-white shadow-md rounded">
                <img
                  class="rounded-t w-full h-64 object-cover object-top bg-white"
                  src="//images.ctfassets.net/ebldurwszic0/4wKSpQizJsEvUuSpZ1kM8v/62e881b7f98daf39027652f75200573e/Vahid_Toosi.jpg"
                >
                <div class="py-4 px-8">
                  <h3 class="text-lg">
                    Vahid Toosi
                  </h3>
                  <p class="text-sm">
                    Styrelseledamot
                  </p>
                </div>
              </div>
              
              <div class="bg-white shadow-md rounded">
                <img
                  class="rounded-t w-full h-64 object-cover object-top bg-white"
                  src="//images.ctfassets.net/ebldurwszic0/6yH4RsiRcJhbJHiHtOgc3x/142726517569d0bfe8d811529fb3614d/Ska__rmavbild_2021-11-01_kl._17.40.39.png"
                >
                <div class="py-4 px-8">
                  <h3 class="text-lg">
                    Michaela Berglund
                  </h3>
                  <p class="text-sm">
                    Styrelseledamot
                  </p>
                </div>
              </div>
              
              <div class="bg-white shadow-md rounded">
                <img
                  class="rounded-t w-full h-64 object-cover object-top bg-white"
                  src="//images.ctfassets.net/ebldurwszic0/5ocYHTCi69YGTUbiSWIgzI/70f75f858780b6cebb0672cc50267bc4/Ska__rmavbild_2021-11-01_kl._17.41.33.png"
                >
                <div class="py-4 px-8">
                  <h3 class="text-lg">
                    Christoffer De Geer
                  </h3>
                  <p class="text-sm">
                    Styrelseledamot
                  </p>
                </div>
              </div>
            </div>
            <p class="py-2" />

            <h4 class="text-xl font-bold pt-8">
              <strong>LEDANDE BEFATTNINGSHAVARE</strong>
            </h4>
            <h5 class="text-lg font-bold	my-4">
              <strong><i>Henrik Karstensen – VD</i></strong>
            </h5>
            <p>
              7 år inom solenergi
            </p>
            <p>
              Tidigare grundare och VD av Svensk Takenergi, en installatör av solceller
            </p>
            <p>
              <strong>Innehav i WEG: </strong>
              1 474 615 aktier
            </p>
            <p class="py-2" />

            <h5 class="text-lg font-bold	my-4">
              <i><strong>Sebastian Hjort – CFO</strong></i>
            </h5>
            <p>
              Utbildning: &nbsp;Fil. kand i företagsekonomi från Stockholms Universitet
            </p>
            <p>
              Sebastian Hjorth är konsult inom ekonomi och ekonomistyrning. Sedan 2019 driver han Ace of Spades som en redovisnings- och konsultbyrå genom vilken han fungerar som inhyrd CFO i ett flertal bolag. Sebastian har sedan 2005 haft ett flertal anställningar med liknande inriktning.
            </p>
            <p>
              <strong>Övriga uppdrag</strong>
            </p>
            <p>
              Ägare och styrelseledamot i Ace of Spades Finance AB, genom vilket Sebastian bl a har uppdrag som interim CFO i QP Games.
            </p>
            <p>
              <strong>Innehav i WEG: </strong>
              0
            </p>
            <p class="py-2" />

            <h5 class="text-lg font-bold	my-4">
              <strong><i>Christer Nilsson – CTO</i></strong>
            </h5>
            <p>
              20 års erfarenhet från solcellsbranschen
            </p>
            <p>
              Erfarenhet från REC, Swemodule och egen konsultverksamhet
            </p>
            <p>
              <strong>Innehav i WEG: </strong>
              1 474 616 aktier
            </p>
            <p class="py-2" />

            <p class="py-2" />

            <h4 class="text-xl font-bold pt-8">
              <strong>STYRELSE</strong>
            </h4>

            <h5 class="text-lg font-bold	my-4">
              <strong><i>Johan Kjell – Styrelseordförande</i></strong>
            </h5>
            <p>
              Utbildning: MSc, Ekonomprogrammet, Lunds Universitet
            </p>
            <p>
              Johan Kjell har sedan 2007 arbetat med rådgivning och investeringar. Han var tidigare ansvarig för förvärv (Head of M&amp;A) på If Skadeförsäkringar och arbetade utöver förvärv med skuldfinansiering (Debt Capital Markets, DCM) och etablering på nya marknader. Dessförinnan arbetade Kjell på Alfred Berg ABN AMRO med med företagsfinansiering (corporate finance), förvärv och fusioner (M&amp;A) och börsintroduktioner (IPOs).
            </p>
            <p>
              <strong>Övriga uppdrag</strong>
            </p>
            <p>
              Styrelseuppdrag i Expassum-koncernen, C2 Safety-koncernen
            </p>
            <p>
              <strong>Innehav i WEG: </strong>
              1 073 667 aktier
            </p>
            <p class="py-2" />

            <h5 class="text-lg font-bold	my-4">
              <i><strong>Vahid Toosi – Styrelseledamot</strong></i>
            </h5>
            <p>
              Utbildning: Studier vid Handelshögskolan i Stockholm, BSc&nbsp;
            </p>
            <p>
              Vahid har de senaste åren verkat internationellt som rådgivare och investerare inom blockkedje-teknologi med fokus på infrastruktur, validering av blockkedjor och NFT:er. 
            </p>
            <p>
              <strong>Övriga uppdrag</strong>
            </p>
            <p>
              Styrelseuppdrag i PlayPark AB, LeiPass AB, Executive coach på SSE Business Lab på Handelshögskolan i Stockholm. Presidential Advisor Free Republic of Liberland.&nbsp;
            </p>
            <p>
              <strong>Innehav i WEG:</strong>
              1 073 667 aktier
            </p>
            <p class="py-2" />
            
            <h5 class="text-lg font-bold	my-4">
              <i><strong>Michaela Berglund – Styrelseledamot</strong></i>
            </h5>
            <p>
              <i>Utbildning: Ekonomi och Marknadsföring vid Uppsala Universitet</i>
            </p>
            <p>
              Michaela Berglund är en aktiv investerare, styrelseledamot och företagsledare, med en lång erfarenhet från olika industrier och marknader. Hon är bland annat vd för Feminvest och har haft ledande positioner inom kommunikation och IR för flertalet företag i tillväxtfas. De senaste åren har hon primärt arbetat inom teknik och finans.
            </p>
            <p>
              <i><br>
              </i>
              <i><strong>Övriga uppdrag</strong></i>
            </p>
            <p>
              Ledande befattningshavare och styrelseledamot i The Future is Female AB, Ledande befattningshavare Terranet AB, Styrelseledamot i Small Talk Ab, Mikrofonden Sverige ekonomisk förening och M Berglund Invest.
            </p>
            <p>
              <i><strong>Innehav i WEG: </strong></i>
              130 000 aktier.
            </p>
            <p class="py-2" />
            <h5 class="text-lg font-bold	my-4">
              <i><strong>Christoffer de Geer – Styrelseledamot</strong></i>
            </h5>
            <p>
              <i>Utbildning:</i>
            </p>
            <p>
              Christoffer är ansvarig för produkter och digitala tillgångar på Goobit Group AB (Head of Group Product &amp; Digital Asset Portfolio Management). Han har många års erfarenhet inom blockchainindustrin och är författare till två böcker om blockkedjeteknologin.
            </p>
            <p>
              <i><strong>Övriga uppdrag</strong></i>
            </p>
            <p>
              Goobit Group AB, Portföljansvarig för produkter och digitala tillgångar (Head of Group Product &amp; Digital Asset Portfolio Management). Styrelseledamot i Various Ventures AB, Crypto 21 AB, Blackriver Energy AB.
            </p>
            <p>
              <i><strong>Innehav i WEG: </strong></i>
              300 000 aktier.
            </p>
            <p class="my-2" />
            <h5 class="text-lg font-bold	my-4">
              <strong><i>Henrik Karstensen – Styrelseledamot &amp; VD</i></strong>
            </h5>
            <p>
              Se ovan
            </p>
          </div>
        </div>
        <div v-if="selected === 'styrelsens-arbete'">
          <div class="w-full max-w-[1000px]">
            <h1>
              Styrelsens arbete
            </h1>

            <p>
              Styrelsen har antagit en arbetsordning samt en VD-instruktion. Styrelsen har inga utskott utan utgör revisions- och ersättningsutskott i sin helhet.
            </p>
            <p class="my-2" />
          </div>
        </div>
        <div v-if="selected === ''">
          <div class="w-full max-w-[1000px]">
            <h1>
              Bolagsstyrning
            </h1>
            <p>
              Bolagsstyrningen i WEG utgår från svensk lagstiftning, främst den svenska Aktiebolagslagen samt Regelverk för emittenter utfärdat av Spotlight Stock Market.
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer
      class="w-full"
      @go-to-index="$emit('go-to-index', $event)"
    />
  </div>
</template>

<script>
import { useAppStore } from '@/stores/app'
import {ref} from 'vue'
import Footer from '@/components/Footer'

export default {
  name: 'IR',
  components: {
    Footer
  },
  setup () {
    const appStore = useAppStore()

    let selected = ref()

    return { appStore, selected }
  },
  data () {
    return {
      content: '<h1> Bolagsstyrning </h1> <p> Bolagsstyrningen i WEG utgår från svensk lagstiftning, främst den svenska Aktiebolagslagen samt Regelverk för emittenter utfärdat av Spotlight Stock Market. </p>'
    }
  },
  mounted () {
    this.selected = this.$route.params.selected ? this.$route.params.selected : ''
  }
}
</script>

<style lang="scss">
.management p {
  @apply my-2;
}
.management {
  h1, h2, h3, h4 {
    @apply font-bold;
  }

  h1 {
    @apply text-4xl pb-2 mb-4;
  }

  h2 {
    @apply text-2xl;
  }

  h3 {
    @apply text-xl;
  }

  h4 {
    @apply text-lg;
  }
  a {
    @apply text-custom-blue-500;
  }
}
</style>