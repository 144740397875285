<template>
  <swiper
    ref="pageSwiper"
    direction="vertical"
    slides-per-view="auto"
    :mousewheel="true"
    :pagination="{
      clickable: true,
    }"
    :free-mode="true"
    :breakpoints="{
      767: {
        freeMode: false,
      }
    }"
    :modules="modules"
    :auto-height="true"
    class="text-custom-gray-900"
    @slideNextTransitionStart="$emit('scroll-slide', $event)"
    @slidePrevTransitionStart="$emit('scroll-slide', $event)"
    @swiper="$emit('swiper', $event)"
  >
    <swiper-slide class="text-custom-gray-900">
      <div class="min-h-[600px] h-full md:h-screen flex flex-col items-center justify-center py-4 px-10 ">
        <div class="max-w-[800px]">
          <h2 class="text-custom-gray-900 text-2xl md:text-3xl lg:text-4xl font-bold mb-8 mt-20 lg:mt-0">
            Välkommen till Windon
          </h2>
          <p class="font-medium mb-6">
            Windon är en pionjär inom förnybar energi i Sverige och en av få svenska producenter av solcellspaneler. Vi är en systemleverantör av solcellssystem där bolaget tillhandahåller ett komplett system för installation av en solcellsanläggning.
          </p>
          <p class="font-medium mb-6">
            Windons kunder är huvudsakligen svenska installatörer som riktar sig mot privatmarknaden, men vi säljer även till fastighetsägare, utvecklare av solcellsparker och industriföretag. Vår ambition är att vara en värdefull partner för kunden och inte bara erbjuda ett konkurrenskraftigt system för solceller utan också att fortlöpande vidareutbilda kunderna för att gemensamt säkerställa en högkvalitativ leverans till slutkund.
          </p>
        </div>
        <div class="flex flex-col md:flex-row w-full items-start mt-6 mb-12 max-w-[1000px]">
          <div class="w-full md:w-1/3 px-4 flex flex-col sm:flex-row md:flex-col items-center sm:items-start md:items-center">
            <div
              class="w-40 h-40 sm:w-16 sm:h-16 sm:mr-4 md:mr-0 sm:mb-8 md:mb-0 flex-shrink-0 md:w-32 md:h-32 mb-2 rounded-full bg-cover bg-center"
              style="background-image: url(/start_solpanel.png);"
            />
            <div class="text-center sm:text-left md:text-center md:mt-4 mb-8">
              <SubTitle bold>
                Solpaneler
              </SubTitle>
              <p>Framtagna med kvalitativa material specifikt för nordiska förhållanden.</p>
              <a
                href="https://windon.encyon.se/produkter/solpaneler"
                class="text-custom-blue-500"
              >Läs mer här</a>
            </div>
          </div>
          <div class="w-full md:w-1/3 px-4 flex flex-col sm:flex-row md:flex-col items-center sm:items-start md:items-center">
            <div
              class="w-40 h-40 sm:w-16 sm:h-16 sm:mr-4 md:mr-0 sm:mb-8 md:mb-0 flex-shrink-0 md:w-32 md:h-32 mb-2 rounded-full bg-cover bg-center"
              style="background-image: url(/start_inverter.png);"
            />
            <div class="text-center sm:text-left md:text-center md:mt-4 mb-8">
              <SubTitle bold>
                Växelriktare
              </SubTitle>
              <p>
                Tätt samarbete med världsledande
                producent av växelriktare för ett optimalt
                system
              </p>
              <a
                href="https://windon.encyon.se/produkter/inverter"
                class="text-custom-blue-500"
              >Läs mer här</a>
            </div>
          </div>
          <div class="w-full md:w-1/3 px-4 flex flex-col sm:flex-row md:flex-col items-center sm:items-start md:items-center">
            <div
              class="w-40 h-40 sm:w-16 sm:h-16 sm:mr-4 md:mr-0 sm:mb-8 md:mb-0 flex-shrink-0 md:w-32 md:h-32 mb-2 rounded-full bg-cover bg-center"
              style="background-image: url(/start_montage.png);"
            />
            <div class="text-center sm:text-left md:text-center md:mt-4 mb-8">
              <SubTitle bold>
                Montagesystem
              </SubTitle>
              <p>
                Patenterat och svensk tillverkat
                montagematerial för enkel montering
                och av högsta kvalitet
              </p>
              <a
                href="https://windon.encyon.se/produkter/takmontage"
                class="text-custom-blue-500"
              >Läs mer här</a>
            </div>
          </div>
        </div>
      </div>
    </swiper-slide>
    <!-- <swiper-slide class="text-custom-gray-900">
      <div class="min-h-[600px] h-full md:h-screen flex flex-col items-center justify-center py-4 px-10 max-w-[600px]">
        <h2 class="text-custom-gray-900 text-2xl md:text-3xl lg:text-4xl font-bold mb-8">
          Windon Energy Group
        </h2>
        <p class="font-medium mb-6">
          Vi föreställer oss en värld där vi använder jordens resurser på ett hållbart sätt.
        </p>
        <p class="font-medium italic mb-6">
          "På <strong>2 timmar</strong> tar jorden emot lika mycket energi från solen som hela världens befolkning använder 
          under <strong class="whitespace-nowrap">1 år.</strong>" <span class="whitespace-nowrap">- Naturskyddsföreningen</span>
        </p>
        <p class="font-medium mb-4">
          Windon jobbar idag för att ta tillvara på den energin.
        </p>
      </div>
    </swiper-slide> -->
    <swiper-slide class="text-white bg-custom-blue-500">
      <div class="min-h-[600px] h-full md:h-screen flex flex-col items-center justify-center w-full py-4 px-8">
        <h2 class="text-white text-2xl md:text-3xl lg:text-4xl font-bold mb-8 mt-8">
          Tillväxtplan
        </h2>

        <p class="font-medium mb-8 lg:mb-16 max-w-[600px]">
          Bolaget har en tillväxtplan som framförallt fokuserar på organisk tillväxt, men kommer också selektivt utvärdera potentiella förvärvsobjekt som kan bidra till att stärka bolagets position på marknaden, alternativt expandera till nya marknader.
        </p>
        <div
          class="font-standard mb-4 flex flex-wrap items-start justify-center"
        >
          <div
            key="growth-1"
            data-index="1"
            class="w-full sm:w-1/2 lg:w-1/4 flex flex-col sm:flex-row lg:flex-col items-center"
          >
            <div
              class="flex justify-center p-8 rounded-full border-2 border-white w-8 h-8 md:w-20 md:h-20 bg-custom-blue-400 mb-4 mt-4 lg:mt-0 mr-4 lg:mr-0"
            >
              <Icon
                name="store"
                class="text-2xl md:text-4xl"
              />
            </div>
            <div class="text-center sm:text-left px-4 mb-4 lg:text-center">
              <SubTitle
                custom-size="text-lg lg:text-2xl"
                bold
              >
                Addera nya återförsäljare
              </SubTitle>
              <p class="text-sm md:text-base">
                Fokusera på att öka återförsäljarnätverket
              </p>
            </div>
          </div>
          <div
            key="growth-2"
            data-index="2"
            class="w-full sm:w-1/2 lg:w-1/4 flex flex-col sm:flex-row lg:flex-col items-center"
          >
            <div
              class="flex justify-center p-8 rounded-full border-2 border-white w-8 h-10 md:w-20 md:h-20 bg-custom-blue-400 mb-4 mt-4 lg:mt-0 mr-4 lg:mr-0"
            >
              <Icon
                name="wrench"
                class="text-2xl md:text-4xl"
              />
            </div>
            <div class="text-center sm:text-left px-4 mb-4 lg:text-center">
              <SubTitle
                custom-size="text-lg lg:text-2xl"
                bold
              >
                Sälja montagesystem separat
              </SubTitle>
              <p class="text-sm md:text-base">
                Intresse finns från installatörer som säljer andra solpaneler än Windon
              </p>
            </div>
          </div>
          <div
            key="growth-3"
            data-index="3"
            class="w-full sm:w-1/2 lg:w-1/4 flex flex-col sm:flex-row lg:flex-col items-center"
          >
            <div
              class="flex justify-center p-8 rounded-full border-2 border-white w-8 h-10 md:w-20 md:h-20 bg-custom-blue-400 mb-4 mt-4 lg:mt-0 mr-4 lg:mr-0"
            >
              <Icon
                name="pie-chart-alt-2"
                class="text-2xl md:text-4xl"
              />
            </div>
            <div class="text-center sm:text-left px-4 mb-4 lg:text-center">
              <SubTitle
                custom-size="text-lg lg:text-2xl"
                bold
              >
                Nya kundsegment
              </SubTitle>
              <p class="text-sm md:text-base">
                Stor potential i solcellsparker, fastighetsbolag mm.
              </p>
            </div>
          </div>
          <div
            key="growth-4"
            data-index="4"
            class="w-full sm:w-1/2 lg:w-1/4 flex flex-col sm:flex-row lg:flex-col items-center"
          >
            <div
              class="flex justify-center p-8 rounded-full border-2 border-white w-8 h-10 md:w-20 md:h-20 bg-custom-blue-400 mb-4 mt-4 lg:mt-0 mr-4 lg:mr-0"
            >
              <Icon
                name="category-alt"
                class="text-2xl md:text-4xl"
              />
            </div>
            <div class="text-center sm:text-left px-4 mb-4 lg:text-center">
              <SubTitle
                custom-size="text-lg lg:text-2xl"
                bold
              >
                Nya produktsegment
              </SubTitle>
              <p class="text-sm md:text-base">
                Addera tex batterilagring
              </p>
            </div>
          </div>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide class="text-white bg-custom-green-500">
      <div class="min-h-[600px] h-full md:h-screen flex flex-col items-center justify-center w-full py-4 px-10">
        <h2 class="text-white text-2xl md:text-3xl lg:text-4xl font-bold mb-8">
          Förväntad tillväxt i Sverige
        </h2>
        <p class="font-medium mb-4">
          Den förväntade solcellskapaciteten i Sverige i MW till 2035.
        </p>
        <div class="absolute bottom-12 xl:bottom-32 left-0 w-11/12 h-1/3 lg:h-1/2 xl:h-2/3">
          <apexchart
            v-if="showApexChart"
            ref="apex"
            type="bar"
            height="100%"
            width="100%"
            :options="dataOptions"
            :series="dataSeries"
          />
        </div>
      </div>
    </swiper-slide>
        
    <!-- <swiper-slide class="text-custom-gray-900 bg-custom-gray-100">
      <div class="min-h-[600px] h-full md:h-screen w-full flex flex-col items-center justify-center px-10">
        <h2 class="text-custom-gray-900 text-2xl md:text-3xl lg:text-4xl font-bold mb-8">
          Rörelsedrivande dotterbolag
        </h2>
        <div class="w-11/12 lg:w-1/3 shadow-xl rounded-md bg-white p-8">
          <h2 class="text-custom-blue-500 text-2xl md:text-3xl lg:text-4xl font-bold mb-8">
            Windon AB
          </h2>
          <p class="font-medium text-base mb-4">
            Windon utvecklar, producerar och säljer solcellssystem, vilka innefattar solcellspaneler, montagesystem och växelriktare av högsta kvalitet. Windon är idag den enda producenten på den svenska marknaden där man på detaljnivå styr sin produktion vid tillverkning av kiselpaneler.
          </p>
          <a
            href="https://windon.se"
            target="_blank"
          ><ActiveButton>Gå till Windon</ActiveButton></a>
        </div>
      </div>
    </swiper-slide> -->
    <swiper-slide class="text-white bg-custom-gray-900">
      <div class="min-h-[600px] h-full md:h-screen w-full flex flex-col items-center justify-center">
        <h2 class="text-white text-2xl md:text-3xl lg:text-4xl font-bold mb-8">
          Kontakt
        </h2>
        <div class="font-medium mb-4">
          <BigTitle>Investor Relations</BigTitle>
          <div>Vahid Toosi</div>
          <div class="mb-8">
            <a
              class="font-bold"
              href="mailto:v@windonenergygroup.se"
            >v@windonenergygroup.se</a>
          </div>
        
          <div>Henrik Karstensen, VD</div>
          <a
            class="font-bold"
            href="mailto:henrik@windon.se"
          >henrik@windon.se</a>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide class="text-custom-gray-900">
      <Footer
        class="w-full"
        @go-to-index="$emit('go-to-index', $event)"
      />
    </swiper-slide>
  </swiper>
</template>

<script>
import { useAppStore } from '@/stores/app'
import time from '@/js/helpers/time'
import Footer from '@/components/Footer'

import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/pagination';

// import required modules
import { Mousewheel, Pagination, FreeMode } from 'swiper';

import gsap from 'gsap'

export default {
  name: 'Home',
  components: {
      Swiper,
      SwiperSlide,
      Footer
    },
  props: {
    showApexChart: {
      type: Boolean,
      default: false
    },
    showGrowth: {
      type: Boolean,
      default: false
    }
  },
  emits: [
  'swiper',
  'scroll-slide'
  ],
  setup () {
    const appStore = useAppStore()

    return { appStore, modules: [Mousewheel, Pagination, FreeMode] }
  },
  data () {
    return {
      dataSeries: [
        {
          name: "MW",
          type: 'column',
          data: [140.03, 230.99, 411.06, 698.05, 1089.23, 1578.02, 2383.64, 11472.07]
        }
      ],
      dataOptions: {
        chart: {
          type: "line",
          stacked: false,
          foreColor: '#CFFBE0',
          toolbar: {
            show: false,
            tools: {
              download: false
            }
          },
        },
        grid: {
          show: true,
          position: 'back',
          xaxis: {
              lines: {
                  show: false
              }
          },   
          yaxis: {
              lines: {
                  show: false
              }
          },  
          row: {
              colors: undefined,
              opacity: 0.5
          },  
          column: {
              colors: undefined,
              opacity: 0.5
          },  
          padding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
          },  
        },
        dataLabels: {
          enabled: false
        },
        colors: ['#fff', '#fff'],
        
        stroke: {
          width: [4, 4, 4],
          curve: 'smooth',
        },
        plotOptions: {
          bar: {
            columnWidth: "40%"
          }
        },
        xaxis: {
          categories: [2016, 2017, 2018, 2019, 2020, 2021, 2022, 2035],
          labels: {
            colors: '#fff'
          },
          axisTicks: {
            show: true,
            color: '#78909C',
          },
        },
        yaxis: [
          {
            seriesName: 'Column A',
            axisTicks: {
              show: false
            },
            axisBorder: {
              show: false,
            },
            title: {
              text: "MW"
            }
          },
          {
            seriesName: 'Column A',
            show: false
          }
        ],
        
        tooltip: {
          shared: false,
          intersect: true,
          x: {
            show: false
          },
          colors: ['#000']
        },
        legend: {
          horizontalAlign: "left",
          offsetX: 40
        }
      }
    }
  },
  methods: {
    onBeforeEnter(el) {
      el.style.opacity = 0
      el.style.height = 0
    },
    onEnter(el, done) {
      console.log("OMG enter")
      gsap.to(el, {
        opacity: 1,
        height: '1.6em',
        delay: el.dataset.index * 1.15,
        onComplete: done
      })
    },
    onLeave(el, done) {
      gsap.to(el, {
        opacity: 0,
        height: 0,
        delay: el.dataset.index * 1.15,
        onComplete: done
      })
    },
    introText(text) {
      return text.split(' ', 15).join(' ') + '...';
    },
    diffFromNow (datetime) {
      if (datetime) return time.diffFromNow(datetime)
    }
  }
}
</script>

<style lang="scss">
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: auto !important;
  /* Center slide text vertically */
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

</style>